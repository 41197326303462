import { useEffect, useState } from 'react';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CardWrapper from '../../components/general/CardWrapper';
import RequestSummary from '../../components/RequestSummary/RequestSummary';
import React, {useCallback} from 'react'
import API from '../../services/api';
import { requestStatuses } from '../../services/requestService';
import { validateAnswerRequest } from '../../services/validations';
import fileImage from '../../lib/img/fileImage.png';
import {useDropzone} from 'react-dropzone'
import { apiUrl } from '../../services/api';

function RequestAdmin() {
  const { id } = useParams();
  const [request, setRequest] = useState(null);
  const [processedDate, setProcessedDate] = useState('');
  const [status, setStatus] = useState(0);
  const [answer, setAnswer] = useState('');
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedFiles, setFiles] = useState(null);
  const [fileError, setFileError] = useState(null);

  const selectedFilesName = () => {
    let selectedFilesName = [];
    for (let file of selectedFiles) {
      selectedFilesName.push(file.name);// += <p>{file.name}</p>;
    }
      return selectedFilesName.map((name, index) => {
          return (
          <option>
            {name}
          </option>)
    }
    );
  }

  const onFileChanges = (e) => {
    let error = '';

    if (e.target.files.length <= 3) {
      for (let file of e.target.files) {
        const fileSize = file.size / (1024 * 1024); // Size in mb
        if (fileSize > 10) {
          error = 'Розмір файлу не може бути більше 10 Мбайт';
          break;
        }
      }
    } else {
      error = 'Максимальна кількість файлів 3';
    }

    setFileError(error);

    let files = null;
    if (!error) {
      files = e.target.files;
    }
    setFiles(files);
  };

  const onDrop = useCallback((acceptedFiles) => {
    let error = '';

    if (acceptedFiles.length <= 3) {
      for (let file of acceptedFiles) {
        const fileSize = file.size / (1024 * 1024); // Size in mb
        if (fileSize > 10) {
          error = 'Розмір файлу не може бути більше 10 Мбайт';
          break;
        }
      }
    } else {
      error = 'Максимальна кількість файлів 3';
    }

    setFileError(error);

    let files = null;
    if (!error) {
      files = acceptedFiles;
    }
    setFiles(files);

  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  useEffect(() => {
    async function fetchData() {
      const result = await API.get('/request/' + id);
      if (result.data) {
      console.log(result.data);

        setRequest(result.data);

        // Date to datetime-local
        if (result.data.processedDate[0] === '0') {
          setProcessedDate('');
        } else {
          const date = new Date(result.data.processedDate);
          date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
          setProcessedDate(date.toISOString().slice(0, 16));
        }

        setStatus(result.data.status);
        setAnswer(result.data.answer);
      }
    }

    fetchData();
  }, [id]);

  function handleSubmit() {
    const isError = validateAnswerRequest({ status, processedDate });
    setErrorMessage(isError);

    if (!isError) {
      API.put('/request/answer/' + id, {
        answer: answer,
        status: Number.parseInt(status),
        processedDate: processedDate,
      }).then(() => {
        if (selectedFiles) {
          const formData = new FormData();
          for (let file of selectedFiles) {
            formData.append('files', file);
          }
          API.post('/file/request?requestId=' + id + '&isAnswer=true', formData);
        }
        history.push('/admin/request/list');
      })
      .catch((error, response) => {
      let message;
        if (error.response) {
          message = error.response.statusText;
        if (error.response.data != "" && error.response.data.message) {
            message = error.response.data.message;
      }
        } else if (error.request && error.request.data) {
          message = error.request.data;
      }
        setErrorMessage(message);
      });
    }
  }

  if (!request) {
    return <h1>Завантаження...</h1>;
  }

  return (
    <div>
    <Link to="/admin/request/list" className="btn btn-secondary">
    ⬅
    </Link>
    <CardWrapper theme="light" title={"Запит №" + request.requestId}>
      <RequestSummary request={request} />
      <FormGroup>
        <FormLabel>Дата обробки:</FormLabel>
        <input
          className="form-control"
          type="date"
          value={processedDate}
          onChange={(e) => setProcessedDate(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Статус:</FormLabel>
        <FormControl as="select" value={status} onChange={(e) => setStatus(e.target.value)}>
          {requestStatuses.map((r, index) => (
            <option key={index} value={index}>
              {r}
            </option>
          ))}
        </FormControl>
      </FormGroup>
      <FormGroup>
        <FormLabel>Відповідь:</FormLabel>
        <FormControl as="textarea" rows={5} value={answer} onChange={(e) => setAnswer(e.target.value)} />
      </FormGroup>
      {request.files && request.files.length > 0 && request.files.filter(d => d.isAnswer === true).length > 0 && (
        <FormGroup>
          <FormLabel>Файли відповіді:</FormLabel>
          <div>
            {request.files.map((f, index) => (
              f.isAnswer == true?
              <a className="btn btn-outline-secondary ml-1" key={index} href={apiUrl + f.path}>
                {f.path.split('.')[1]}
              </a>:""
            ))}
          </div>
        </FormGroup>
      )}
      <FormGroup>
        {fileError && <div className="text-danger">{fileError}</div>}
        <FormLabel><span>Виберіть файли (Макс: 10Мб)</span></FormLabel>
        <input className="request-form-file" type="file" id="files" multiple onChange={onFileChanges} {...getInputProps()} />
        <div className="drop-area" {...getRootProps()}>
            {/* <div {...getRootProps()}>
            <input  />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div> */}
          <label htmlFor="files" className="request-file-label">
            <span>Обрати файл</span>
            <img src={fileImage} alt="fileImage" />
          </label>
          {/* <span>{selectedFiles && "Файлiв: " + selectedFiles.length}</span> */}
          <span>{selectedFiles && selectedFilesName()}</span>
        </div>
      </FormGroup>
      {errorMessage ? <div className="text-danger my-2 errorMessageRed" >{errorMessage}</div> : null}
      <Button variant="warning" id="btn-warning-theme" onClick={handleSubmit}>
        Подати
      </Button>
    </CardWrapper>
    </div>
  );
}

export default RequestAdmin;
