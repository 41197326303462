import { Field } from "formik";
import { FormGroup, FormLabel } from "react-bootstrap";
import { Link } from "react-router-dom";

function InputField(props) {
    return (
        <FormGroup>
            <FormLabel htmlFor={props.name}>{props.displayName || props.name}:</FormLabel>
            <Field className='form-control' type={props.type} name={props.name} id={props.name} placeholder={props.placeholder} validate={props.validation} />
            {props.touched && props.error ? <span className='text-danger'>{props.error}</span> : null}
        </FormGroup>
    );
}

export default InputField;