import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CompanyForm from '../../components/CompanyForm/CompanyForm';
import CardWrapper from '../../components/general/CardWrapper';
import API from '../../services/api';

function CompanyEdit() {
  const [company, setCompany] = useState(null);
  const [errorMessage, setError] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const result = await API.get('/admin/company/' + id);
      if (result) {
        setCompany({
          name: result.data.name,
          edrpou: result.data.edrpou,
        });
      }
    }

    fetchData();
  }, [id]);

  function onUpdate(values) {
    API.put('/admin/company/' + id, {
      name: values.companyName,
      edrpou: values.edrpou,
    })
      .then(() => {
        history.push('/admin/company/list');
      })
      .catch((errors) => {
        if (errors.response?.data?.company) {
          setError(errors.response.data.company.join(', '));
        }
      });
  }

  return (
    <CardWrapper theme="light" title="Редагувати замовника">
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      {company ? (
        <CompanyForm type="edit" name={company.name} edrpou={company.edrpou} onSubmit={onUpdate} />
      ) : (
        <>
          <h1>Завантаження...</h1>
          <Link to="/admin/company/list" className="btn btn-secondary">
            Назад
          </Link>
        </>
      )}
    </CardWrapper>
  );
}

export default CompanyEdit;
