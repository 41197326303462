import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CardWrapper from '../../components/general/CardWrapper';
import LicenseForm from '../../components/LicenseForm/LicenseForm';
import API from '../../services/api';

function LicenseEdit() {
  const [errorMessage, setError] = useState(null);
  const [license, setLicense] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    API.get('/admin/license/' + id).then((res) => {
      setLicense(res.data);
    });
  }, [id]);

  if (!license) {
    return (
      <>
        <h2>Loading...</h2>
        <Link to="/admin/license/list" className="btn btn-secondary">
          Back
        </Link>
      </>
    );
  }

  function onUpdate(values) {
    API.put('/admin/license/' + id, {
      isActive: values.isActive,
      companyId: values.companyId,
      productId: values.productId,
    })
      .then(() => {
        history.push('/admin/license/list');
      })
      .catch((errors) => {
        if (errors.response?.data?.license) {
          setError(errors.response.data.license.join(', '));
        }
      });
  }

  return (
    <CardWrapper theme="light" title="Редагувати ліцензію">
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      <LicenseForm license={license} type="edit" onClick={onUpdate} />
    </CardWrapper>
  );
}

export default LicenseEdit;
