import { useEffect } from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import CardWrapper from '../../components/general/CardWrapper';
import API from '../../services/api';
import { Form } from 'react-bootstrap';
import './Product.css';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [productsAll, setProductsAll] = useState([]);
  const [searchProduct, setSearchProduct] = useState(null);
  const [selectedStatus, setStatus] = useState(0);
  const [fixeTableHead, setFixeTableHead] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const result = await API.get('/admin/product');
      if (result.data) {
        setProductsAll(result.data);
        setProducts(result.data);
      }
    }

    window.addEventListener("scroll", function (ev) {
      handleScroll(ev);
    }, false);

    fetchData();
  }, []);

  const handleSubmitSearch = () => {
      setProducts(productsAll.filter(word => word.name.toLowerCase().includes(searchProduct.toLowerCase())));
  };

  const handleScroll= (event) =>{
    var scrolled = document.scrollingElement.scrollTop;
    if(scrolled>100){
        setFixeTableHead(true);
      }else{
        setFixeTableHead(false);
      }
  };

  const showTableHead = () => {
    if(fixeTableHead){
      return (
        <div className="page-item-header-fixed">
          <Container>
          <div className="bg-white">
            <Form.Group className='form-group-bar'>
              <Link className="btn-light-theme m-2" to="/admin/product">
                Додати
              </Link>
                <Form.Control
                  type="search"
                  placeholder=""
                  className="me-2 form-group-bar-search"
                  aria-label="Search"
                  onChange={(e) => setSearchProduct(e.target.value)}
                />
                <button 
                variant="outline-success" 
                className="me-2 form-group-bar-search-button border-left-0 border" 
                onClick={handleSubmitSearch}></button>
            </Form.Group>
            <CardWrapper theme="light" title="Продукти">
              <Table bordered className="request-item-header">
                <thead className="bg-light border-right-left-bottom-0">
                  <tr>
                    <th className="page-product-list-number">№</th>
                    <th>Продукт</th>
                    <th className="page-product-list-view"></th>
                  </tr>
                </thead>
              </Table>
            </CardWrapper>
          </div> 
          </Container> 
        </div>  
      );
    }
    }

  return (
    <div>
      {showTableHead()}
      <div>
        <Form.Group className='form-group-bar'>
            <Link className="btn-light-theme m-2" to="/admin/product">
              Додати
            </Link>
              <Form.Control
                type="search"
                placeholder=""
                className="me-2 form-group-bar-search"
                aria-label="Search"
                onChange={(e) => setSearchProduct(e.target.value)}
              />
              <button 
              variant="outline-success" 
              className="me-2 form-group-bar-search-button border-left-0 border" 
              onClick={handleSubmitSearch}></button>
          
        </Form.Group>
      </div>
      <CardWrapper theme="light" title="Продукти">
        <Table bordered className="request-item-header">
          <thead className="bg-light border-right-left-bottom-0">
            <tr>
              <th className="page-product-list-number">№</th>
              <th>Продукт</th>
              <th className="page-product-list-view"></th>
            </tr>
          </thead>
          <tbody>
            {products.map((p) => (
              <tr key={p.productId} className="product-item product-item-all-regulatory" tabindex="1">
                <td className='text-center'>{p.productId}</td>
                <td>{p.name}</td>
                <td className='text-center btn-record-view-edit'>
                  <Link className="btn btn-warning-edit btn-sm ml-2" to={'/admin/product/edit/' + p.productId}>
                    {/* Редагувати */}
                  </Link>
                  <Link className="btn btn-primary-look btn-sm" to={'/admin/product/' + p.productId}>
                    {/* Переглянути */}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardWrapper>
    </div>
  );
}

export default ProductList;
