import { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import React, {useCallback} from 'react'
import API from '../../services/api';
import { requestLevels } from '../../services/requestService';
import { validateRequest } from '../../services/validations';
import fileImage from '../../lib/img/fileImage.png';
import './Request.css';
import {useDropzone} from 'react-dropzone'

function Request() {
  const [company, setCompany] = useState({ name: 'Loading...' });
  const [products, setProducts] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(-1);
  const [selectedLicense, setSelectedLicense] = useState(-1);
  const [description, setDescription] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(-1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedDate, setDate] = useState('');
  const [selectedFiles, setFiles] = useState(null);
  const [fileError, setFileError] = useState(null);

  const history = useHistory();

  const handleSelectProduct = (e) => {
    const productId = Number.parseInt(e.target.value);
    setSelectedProduct(productId);

    const license = licenses.find((l) => l.product?.productId === productId);
    if (license) {
      setSelectedLicense(license.licenseId);
    }
  };

  const handleSelectLicense = (e) => {
    const licenseId = Number.parseInt(e.target.value);
    setSelectedLicense(licenseId);

    const productId = licenses.find((l) => l.licenseId === licenseId).product?.productId;
    if (productId && products.find((p) => p.productId === productId)) {
      setSelectedProduct(productId);
    }
  };

  const addDays = (requestLevel) => {
    let addDaysCount = 3;
    if(requestLevel === 2)
      addDaysCount = 14;
    const date = new Date();
    date.setDate(date.getDate() + addDaysCount);
    setDate(date.toLocaleDateString("fr-CA"));
  }

  const selectedFilesName = () => {
    let selectedFilesName = [];
    for (let file of selectedFiles) {
      selectedFilesName.push(file.name);// += <p>{file.name}</p>;
    }
      return selectedFilesName.map((name, index) => {
          return (
          <option>
            {name}
          </option>)
    }
    );
      
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateRequest({ description, selectedProduct, selectedLicense, selectedLevel, selectedDate });

    setErrorMessage(error);

    if (!error) {
      API.post('request', {
        description: description,
        level: selectedLevel,
        productId: selectedProduct,
        licenseId: selectedLicense,
        desiredDate: new Date(selectedDate),
      })
        .then((res) => {
          if (selectedFiles) {
            const formData = new FormData();
            for (let file of selectedFiles) {
              formData.append('files', file);
            }
            API.post('/file/request?requestId=' + res.data.requestId, formData);
          }

          history.push(`request/success/${res.data.requestId}`);
        })
        .catch((error) => {
          if (error.response) setErrorMessage(error.response.data.request.join(', '));
        });
    }
  };

  const onFileChanges = (e) => {
    let error = '';

    if (e.target.files.length <= 3) {
      for (let file of e.target.files) {
        const fileSize = file.size / (1024 * 1024); // Size in mb
        if (fileSize > 10) {
          error = 'Розмір файлу не може бути більше 10 Мбайт';
          break;
        }
      }
    } else {
      error = 'Максимальна кількість файлів 3';
    }

    setFileError(error);

    let files = null;
    if (!error) {
      files = e.target.files;
    }
    setFiles(files);
  };

  const onDrop = useCallback((acceptedFiles) => {
    let error = '';

    if (acceptedFiles.length <= 3) {
      for (let file of acceptedFiles) {
        const fileSize = file.size / (1024 * 1024); // Size in mb
        if (fileSize > 10) {
          error = 'Розмір файлу не може бути більше 10 Мбайт';
          break;
        }
      }
    } else {
      error = 'Максимальна кількість файлів 3';
    }

    setFileError(error);

    let files = null;
    if (!error) {
      files = acceptedFiles;
    }
    setFiles(files);

  }, [])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  useEffect(() => {
    async function fetchData() {
      const companyResult = await API.get('user/company');
      setCompany(companyResult.data);

      const productsResult = await API.get('user/products');
      setProducts(productsResult.data);

      const licenseResult = await API.get('user/license');
      setLicenses(licenseResult.data);
    }

    fetchData();
  }, []);

  return (
    <div className="request-card">
      <div className="request-card-title h5">Створити новий запит</div>
      <Form onSubmit={handleSubmit}>
        {errorMessage ? <div className="text-danger my-2">{errorMessage}</div> : null}
        <Form.Group>
          <Form.Label htmlFor="company">Замовник:</Form.Label>
          <Form.Control id="company" value={company?.name} readOnly />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="product">Продукт:</Form.Label>
          <Form.Control as="select" id="product" value={selectedProduct} onChange={handleSelectProduct}>
            <option disabled value="-1">
              Виберіть продукт
            </option>
            {products.map((p) => (
              <option value={p.productId} key={p.productId}>
                {p.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {/* <Form.Group>
          <Form.Label htmlFor="license">Ліцензія:</Form.Label>
          <Form.Control as="select" id="license" value={selectedLicense} onChange={handleSelectLicense}>
            <option disabled value="-1">
              Виберіть ліцензію
            </option>
            {licenses.map((l) => (
              <option value={l.licenseId} key={l.licenseId}>
                {`#${l.licenseId} - ${l.product?.name}`}
              </option>
            ))}
          </Form.Control>2022-06-21
        </Form.Group> */}
        <Form.Group>
          <Form.Label htmlFor="level">Рівень запиту:</Form.Label>
          <Form.Control
            as="select"
            id="level"
            value={selectedLevel}
            onChange={(e) => {setSelectedLevel(Number.parseInt(e.target.value)); 
              addDays(Number.parseInt(e.target.value));}}
          >
            <option disabled value="-1">
              Виберіть рівень запиту
            </option>
            {requestLevels.map((rl, index) => {
                if (index > 1){
                  return (
                  <option value={index} key={index}>
                    {rl}
                  </option>)
                  }
            }
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="date">Планова дата виконання:</Form.Label>
          <input
            className="form-control"
            id="date"
            type="date"
            value={selectedDate}
            // onChange={(e) => setDate(e.target.value)}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <Form.Label htmlFor="description">Опис:</Form.Label>
          <Form.Control
            as="textarea"
            id="description"
            placeholder="Введіть опис"
            rows={10}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          {fileError && <div className="text-danger">{fileError}</div>}
          <Form.Label><span>Виберіть файли (Макс: 10Мб)</span></Form.Label>
          <input className="request-form-file" type="file" id="files" multiple onChange={onFileChanges} {...getInputProps()} />
          <div className="drop-area" {...getRootProps()}>
             {/* <div {...getRootProps()}>
              <input  />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div> */}
            <label htmlFor="files" className="request-file-label">
              <span>Обрати файл</span>
              <img src={fileImage} alt="fileImage" />
            </label>
            {/* <span>{selectedFiles && "Файлiв: " + selectedFiles.length}</span> */}
            <span>{selectedFiles && selectedFilesName()}</span>
          </div>
        </Form.Group>
        {errorMessage ? <div className="text-danger my-2 errorMessageRed" >{errorMessage}</div> : null}
        <button type="submit" className="request-btn">
          Подати
        </button>
      </Form>
    </div>
  );
}

export default Request;
