import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProvideAuth from './components/general/ProvideAuth';
import PrivateRoute from './components/general/PrivateRoute';
import AdminLayout from './components/Layout/AdminLayout';
import Account from './pages/Auth/Account';
import MainLayout from './components/Layout/MainLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <PrivateRoute path="/admin">
            <AdminLayout />
          </PrivateRoute>
          <Route path="/account">
            <Account />
          </Route>
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

export default App;
