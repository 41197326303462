import { useEffect } from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import CardWrapper from '../../components/general/CardWrapper';
import API from '../../services/api';
import { Form } from 'react-bootstrap';
import './Company.css';

function CompanyList() {
  const [companies, setCompanies] = useState([]);
  const [companiesAll, setCompaniesAll] = useState([]);
  const [searchCompanies, setSearchCompanies] = useState(null);
  const [fixeTableHead, setFixeTableHead] = useState(null);

  const tbodyTh = ['', 'Замовник', 'ЄДРПОУ'];
  const [selectedStatus, setStatus] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const result = await API.get('admin/company');
      setCompaniesAll(result.data);
      setCompanies(result.data);
    }

    window.addEventListener("scroll", function (ev) {
        handleScroll(ev);
    }, false);
    
    fetchData();
  }, []);

  const handleSubmitSearch = () => {
    if(selectedStatus == 1){
      setCompanies(companiesAll.filter(word => word.name.toLowerCase().includes(searchCompanies.toLowerCase())));
    }else
    if(selectedStatus == 2){
      setCompanies(companiesAll.filter(word => word.edrpou.includes(searchCompanies)));
    }else
      setCompanies(companiesAll.filter(word => word.name.includes(searchCompanies) || word.edrpou.includes(searchCompanies)));
  };

  const handleScroll= (event) =>{
    var scrolled = document.scrollingElement.scrollTop;
    if(scrolled>100){
        setFixeTableHead(true);
      }else{
        setFixeTableHead(false);
      }
  };

  const showTableHead = () => {
    if(fixeTableHead){
      return (
        <div className="page-item-header-fixed">
          <Container>
          <div className="bg-white">
            <Form.Group className='form-group-bar'>
              <Link className="btn-light-theme m-2" to="/admin/company">
                Додати
              </Link>
              <Form.Control id="filter" as="select" value={selectedStatus} onChange={(e) => setStatus(e.target.value)}>
                {tbodyTh.map((s, index) => (
                  <option key={index} value={index}>
                    {s}
                  </option>
                ))}
              </Form.Control>
                <Form.Control
                  type="search"
                  placeholder=""
                  className="me-2 form-group-bar-search"
                  aria-label="Search"
                  onChange={(e) => setSearchCompanies(e.target.value)}
                  />
                <button variant="outline-success" 
                  className="me-2 form-group-bar-search-button border-left-0 border"
                    onClick={handleSubmitSearch}></button>
            </Form.Group>
            <CardWrapper theme="light" title="Замовники">
              <Table bordered className="request-item-header">
                <thead className="bg-light border-right-left-bottom-0">
                  <tr>
                    <th className="page-company-list-number">№</th>
                    <th>Замовник</th>
                    <th className="page-company-list-edr">ЄДРПОУ</th>
                    <th className="page-company-list-view"></th>
                  </tr>
                </thead>
              </Table>
            </CardWrapper>
          </div> 
          </Container> 
        </div>  
      );
    }
    }

  return (
    <div>
    {showTableHead()}
    <div>
      <Form.Group className='form-group-bar'>
          <Link className="btn-light-theme m-2" to="/admin/company">
            Додати
          </Link>
          <Form.Control id="filter" as="select" value={selectedStatus} onChange={(e) => setStatus(e.target.value)}>
            {tbodyTh.map((s, index) => (
              <option key={index} value={index}>
                {s}
              </option>
            ))}
          </Form.Control>
            <Form.Control
              type="search"
              placeholder=""
              className="me-2 form-group-bar-search"
              aria-label="Search"
              onChange={(e) => setSearchCompanies(e.target.value)}
              />
            <button variant="outline-success" 
              className="me-2 form-group-bar-search-button border-left-0 border"
              onClick={handleSubmitSearch}></button>
      </Form.Group>
    </div>
    <CardWrapper theme="light" title="Замовники">
      <Table bordered className="request-item-header">
        <thead className="bg-light border-right-left-bottom-0">
          <tr>
            <th className="page-company-list-number">№</th>
            <th>Замовник</th>
            <th className="page-company-list-edr">ЄДРПОУ</th>
            <th className="page-company-list-view"></th>
          </tr>
        </thead>
        <tbody>
          {companies.map((c) => (
            <tr key={c.companyId}>
              <td className='text-center'>{c.companyId}</td>
              <td>{c.name}</td>
              <td className='text-center'>{c.edrpou}</td>
              <td className='text-center btn-record-view-edit'>
                {/* <Link to={`/admin/company/delete/${c.companyId}`} className="btn btn-sm btn-warning-delete"> */}
                  {/* Видалити */}
                {/* </Link> */}
                <Link to={`/admin/company/edit/${c.companyId}`} className="btn btn-sm btn-warning-edit">
                  {/* Редагувати */}
                </Link>
                <Link to={`/admin/company/${c.companyId}`} className="btn btn-sm btn-primary-look ml-1">
                  {/* Переглянути */}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardWrapper>
    </div>
  );
}

export default CompanyList;
