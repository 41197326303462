import { createContext, useContext } from 'react';
import { useAuthProvider } from '../../services/authService';

const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);

export default ProvideAuth;
