import axios from 'axios';
// TODO: set from environment variables
// Development
//export const apiUrl = 'https://localhost:5001';
// Production
// export const apiUrl = process.env.NODE_ENV === 'development' ? 'https://localhost:5001' : '';
// export const apiUrl = 'http://supportkdoc.kai.ua';
//export const apiUrl = 'http://192.168.2.45:5000';
export const apiUrl = 'https://supportkdoc.kai.ua';

const API = axios.create({
  baseURL: apiUrl + '/api/',
});

export default API;
