import { Card } from 'react-bootstrap';
import './CardWrapper.css';

function CardWrapper({ children, theme, title }) {
  return (
    <Card className="card-first">
      <Card.Header className={`${theme !== 'light' ? 'bg-'+ theme +' text-white' : 'text-dark-customizable h5'} text-center`}>
        {title}
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

export default CardWrapper;
