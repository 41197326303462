import { useEffect } from 'react';
import { useState } from 'react';
import { Card, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Redirect, useParams } from 'react-router';
import RequestSummary from '../../components/RequestSummary/RequestSummary';
import API from '../../services/api';
import { getStatus } from '../../services/requestService';
import './RequestViewer.css';
import { apiUrl } from '../../services/api';

function RequestViewer() {
  const { id } = useParams();
  const [processedDate, setDate] = useState(null);
  const [request, setRequest] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (Number.parseInt(id)) {
        const result = await API.get(`request/${id}`);
        setRequest(result.data);

        if (result.data.processedDate[0] === '0') {
          setDate('Дата не встановлена');
        } else {
          setDate(new Date(result.data.processedDate).toLocaleDateString());
        }
      }
    }

    fetchData();
  }, [id]);

  return !Number.parseInt(id) ? (
    <Redirect to="/request" />
  ) : (
    <div>
    <Link to="/request/list" className="btn btn-secondary">
    ⬅
    </Link>
    <Card>
      <Card.Body>
        <Card.Title className="text-center">Запит №{id}</Card.Title>
        {!request ? (
          <h4>Завантаження...</h4>
        ) : (
          <>
            <RequestSummary request={request} />
            <FormGroup>
              <FormLabel>Дата обробки:</FormLabel>
              <FormControl value={processedDate} readOnly />
            </FormGroup>
            <FormGroup>
              <FormLabel>Статус:</FormLabel>
              <FormControl value={getStatus(request.status)} readOnly />
            </FormGroup>
            <FormGroup>
              <FormLabel>Відповідь:</FormLabel>
              <FormControl value={request.answer} as="textarea" rows={5} readOnly />
            </FormGroup>
            {request.files && request.files.length > 0 && request.files.filter(d => d.isAnswer === true).length > 0 && (
              <FormGroup>
                <FormLabel>Файли відповіді:</FormLabel>
                <div>
                  {request.files.map((f, index) => (
                    f.isAnswer == true?
                    <a className="btn btn-outline-secondary ml-1" key={index} href={apiUrl + f.path}>
                      {f.path.split('.')[1]}
                    </a>:""
                  ))}
                </div>
              </FormGroup>
            )}
          </>
        )}
      </Card.Body>
    </Card>
    </div>
  );
}

export default RequestViewer;
