import { Field, Form, Formik } from 'formik';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { validateRequired } from '../../services/validations';

function ProductForm({ product, type, onSubmit }) {
  return (
    <Formik
      initialValues={{
        productName: product.name,
      }}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <FormGroup>
            <FormLabel htmlFor="productName">Назва продукту</FormLabel>
            <Field
              name="productName"
              id="productName"
              className="form-control"
              validate={validateRequired}
              placeholder="Введіть назву продукту"
            />
            {touched.productName && errors.productName ? <div className="text-danger">{errors.productName}</div> : null}
          </FormGroup>
          <Button variant={type === 'edit' ? 'warning' : 'primary'} id="btn-warning-theme" type="submit">
            {/* {type === 'edit' ? 'Редагувати' : 'Додати'} */}
            Зберегти
          </Button>
          <Link to="/admin/product/list" className="btn btn-secondary ml-2">
            Скасувати
          </Link>
        </Form>
      )}
    </Formik>
  );
}

export default ProductForm;
