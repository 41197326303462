import { useState } from 'react';
import { useHistory } from 'react-router';
import CardWrapper from '../../components/general/CardWrapper';
import ProductForm from '../../components/ProductForm/ProductForm';
import API from '../../services/api';

function ProductCreate() {
  const [product, setProduct] = useState({ name: '' });
  const [errorMessage, setError] = useState(null);
  const history = useHistory();

  function onCreate(values) {
    setProduct({ name: values.productName });
    API.post('/admin/product', {
      name: values.productName,
    })
      .then(() => {
        history.push('/admin/product/list');
      })
      .catch((error) => {
        if (error.response?.data?.productDto) {
          setError(error.response?.data.productDto.join(', '));
        }
      });
  }

  return (
    <CardWrapper theme="light" title="Додати новий продукт">
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      <ProductForm product={product} type="create" onSubmit={onCreate} />
    </CardWrapper>
  );
}

export default ProductCreate;
