import { Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, FormCheck, FormGroup, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import API from '../../services/api';
import { validateNumber, validateNumberInComboList } from '../../services/validations';

function LicenseForm({ type, onClick, license }) {
  const [products, setProducts] = useState([]);
  const [companies, setCompanies] = useState([]);

  const [textLicenseId, onChangeText] = useState("");
  const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
  let stateTemp = "";

  useEffect(() => {
    API.get('/admin/company').then((res) => {
      setCompanies(res.data);
    });

    API.get('/admin/product').then((res) => {
      setProducts(res.data);
    });
  }, []);

  return (
    <Formik
      initialValues={{
        licenseId: license.licensesIdStr,
        isActive: license.isActive,
        productId: license.productId,
        companyId: license.companyId,
      }}
      onSubmit={onClick}
    >
      {({ errors, touched }) => (
        <Form>
          {/* {type !== 'edit' && (
            
          )} */}
          <FormGroup>
              <FormLabel htmlFor="licenseId">Ліцензія:</FormLabel>
              <Field name="licenseId" id="licenseId" className="form-control" validate={validateNumber}/>
              {touched.licenseId && errors.licenseId ? <span className="text-danger">{errors.licenseId}</span> : null}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="productId">Продукт:</FormLabel>
            <Field name="productId" id="productId" className="form-control" as="select" validate={validateNumberInComboList}>
              <option value="-1" disabled>
                Виберіть продукт
              </option>
              {products.map((p) => (
                <option key={p.productId} value={p.productId}>
                  {p.name}
                </option>
              ))}
            </Field>
            {touched.productId && errors.productId ? <span className="text-danger">{errors.productId}</span> : null}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="companyId">Замовник:</FormLabel>
            <Field name="companyId" id="companyId" className="form-control" as="select" validate={validateNumberInComboList}>
              <option value="-1" disabled>
                Виберіть замовника
              </option>
              {companies.map((c) => (
                <option key={c.companyId} value={c.companyId}>
                  {c.name}
                </option>
              ))}
            </Field>
            {touched.companyId && errors.companyId ? <span className="text-danger">{errors.companyId}</span> : null}
          </FormGroup>
          <FormCheck>
            <Field name="isActive" type="checkbox" id="isActive" className="form-check-input" />
            <FormCheck.Label htmlFor="isActive">Активна</FormCheck.Label>
          </FormCheck>
          <Button type="submit" variant={type !== 'edit' ? 'primary' : 'warning'} id="btn-warning-theme" className="my-3">
            {/* {type === 'edit' ? 'Редагувати' : 'Додати'} */}
            Зберегти
          </Button>
          <Link className="my-3 btn btn-secondary ml-2" to="/admin/license/list">
          Скасувати
          </Link>
        </Form>
      )}
    </Formik>
  );
}

export default LicenseForm;
