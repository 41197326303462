import { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CardWrapper from '../../components/general/CardWrapper';
import API from '../../services/api';

function LicenseView() {
  const { id } = useParams();
  const [license, setLicense] = useState(null);

  useEffect(() => {
    if (Number.parseInt(id)) {
      API.get('/admin/license/' + id).then((res) => {
        setLicense(res.data);
      });
    }
  }, [id]);

  if (!license) {
    return (
      <>
        <h2>Loading...</h2>
        {/* <Link to="/admin/license/list" onClick={window.history.back()} className="btn btn-secondary"> */}
        <input name="action" onClick={() =>{window.history.back(); return false;}} className="btn btn-secondary" type="submit" value="Назад"/>
      </>
    );
  }

  return (
    <div>
      <input name="action" onClick={() =>{window.history.back(); return false;}} className="btn btn-secondary" type="submit" value="⬅"/>
    <CardWrapper theme="light" title="Ліцензія">
      <Form.Group>
        <Form.Label>Ліцензія:</Form.Label>
        <Form.Control readOnly value={license.licensesIdStr} />
      </Form.Group>
      <CardWrapper theme="light" title="Продукт:">
        <Form.Group>
          <Form.Label>ID продукту:</Form.Label>
          <Form.Control readOnly value={license.product?.productId} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Назва продукту:</Form.Label>
          <Form.Control readOnly value={license.product?.name} />
        </Form.Group>
      </CardWrapper>
      <CardWrapper theme="light" title="Замовник:">
        <Form.Group>
          <Form.Label>ID замовника:</Form.Label>
          <Form.Control readOnly value={license.company?.companyId} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Назва замовника:</Form.Label>
          <Form.Control readOnly value={license.company?.name} />
        </Form.Group>
        <Form.Group>
          <Form.Label>ЄДРПОУ замовника:</Form.Label>
          <Form.Control readOnly value={license.company?.edrpou} />
        </Form.Group>
      </CardWrapper>
    </CardWrapper>
    </div>
  );
}

export default LicenseView;
