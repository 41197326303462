import { useParams } from 'react-router';

function SuccessCreated() {
  const { id } = useParams();
  setTimeout(function(){
        window.location.href = '/request/list';
     }, 5000);
  return (
    <>
      <h1 className="text-center text-white bg-primary p-2 m-2">Запит №{id} надіслано</h1>
    </>
  );
}

export default SuccessCreated;
