import { useEffect } from 'react';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router';
import RequestItem from '../../components/RequestItem/RequestItem';
import API from '../../services/api';
import './RequestList.css';

function RequestList() {
  const [requests, setRequests] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const reqResult = await API.get('request');
      setRequests(reqResult.data);
    }

    fetchData();
  }, []);

  const handleSelect = (id) => {
    history.push(`/request/${id}`);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title className="text-center">Список запитів</Card.Title>
        <div className="card card-request card-request-user">
          <div className="card-body request-item-header row bg-light card-request-item" >
            <div className="color-td-heder"></div>
            <div className="col">Дата</div>
            <div className="col">Статус</div>
            <div className="col">Рівень</div>
            <div className="col">Ліцензія</div>
            <div className="col">Продукт</div>
            {/* <div className="col">Компанія</div> */}
          </div>  
          {!requests.length ? (
            <h4>Тут все ще порожньо</h4>
          ) : (
            requests.map((r) => <div className="card-request-item"><RequestItem request={r} onClick={handleSelect} key={r.requestId} /> </div> )
          )}
        </div>  
      </Card.Body>
    </Card>
  );
}

export default RequestList;
