import { useState } from 'react';
import { useHistory } from 'react-router';
import CardWrapper from '../../components/general/CardWrapper';
import LicenseForm from '../../components/LicenseForm/LicenseForm';
import API from '../../services/api';

function LicenseCreate() {
  const [license, setLicense] = useState({
    licenseId: "",
    LicensesIdStr: "",
    productId: -1,
    companyId: -1,
    isActive: true,
  });
  const [errorMessage, setError] = useState(null);
  const history = useHistory();

  function onCreate(values) {
    const newLicense = {
      licenseId: values.licenseId,
      LicensesIdStr: values.licenseId,
      productId: values.productId,
      companyId: values.companyId,
      isActive: values.isActive,
    };
    API.post('/admin/license', newLicense)
      .then(() => {
        setLicense(newLicense);
        history.push('/admin/license/list');
      })
      .catch((errors) => {
        if (errors.response?.data?.Company) {
          setError(errors.response.data.Company.join(', '));
        }
      });
  }

  return (
    <CardWrapper title="Додати нову ліцензію" theme="light">
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      <LicenseForm type="create" license={license} onClick={onCreate} />
    </CardWrapper>
  );
}

export default LicenseCreate;
