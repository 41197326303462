import { Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import PrivateRoute from '../../components/general/PrivateRoute';
import { useAuth } from '../../components/general/ProvideAuth';
import './Account.css';
import ConfirmEmail from './ConfirmEmail';
import PasswordReset from './PasswordReset';
import Login from './Login';
import Logout from './Logout';
import Register from './Register';

function Account() {
  const auth = useAuth();

  return (
    <div className="account-panel">
      <Switch>
        <Route path="/account/signin">
          {auth.user ? (
            <h1>
              Ви вже автентифіковані. <Link to="/account/signout">Вийти</Link>
            </h1>
          ) : (
            <Login />
          )}
        </Route>
        <Route path="/account/signup">
          <Register />
        </Route>
        <Route path="/account/confirm">
          <ConfirmEmail />
        </Route>
        <Route path="/account/password_reset">
          <PasswordReset />
        </Route>
        <PrivateRoute path="/account/signout">
          <Logout />
        </PrivateRoute>
        <Route>
          <Redirect to="/account/signin" />
        </Route>
      </Switch>
    </div>
  );
}

export default Account;
