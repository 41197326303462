import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import CardWrapper from '../../components/general/CardWrapper';
import Pagination from '../../components/Pagination/Pagination';
import RequestItem from '../../components/RequestItem/RequestItem';
import API from '../../services/api';
import { requestStatuses, requestLevels } from '../../services/requestService';
import { getLevel, getStatus } from '../../services/requestService';
import '../../services/DateTimeFormat';
import { Link } from 'react-router-dom';

function RequestAdminList() {
  const history = useHistory();
  const [requests, setRequests] = useState([]);
  const [requestsAll, setRequestsAll] = useState([]);
  const [searchRequests, setSearchRequests] = useState(null);
  const [selectedParams, setParams] = useState(0);
  const [selectedStatus, setStatus] = useState(0);
  const [selectedLevel, setLevel] = useState(null);
  const [selectedDate, setDate] = useState(null);
  const [selectedLicenseId, setLicenseId] = useState(null);
  const [selectedProductName, setProductName] = useState(null);
  const [selectedCompanyName, setCompanyName] = useState(null);
  const [sort, setSort] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const { page } = useParams();
  const [fixeTableHead, setFixeTableHead] = useState(null);

  const tbodyTh = ['','Дата', 'Статус', 'Рівень', 'Ліцензія', 'Продукт', 'Замовник'];

  const pageSize = 20;

  const handleSubmitSearch = () => {
    setStatus(0);
    setLevel(null);
    setDate(null);
    setLicenseId(null);
    setProductName(null);
    setCompanyName(null);
    if(selectedParams == 1){
      setDate(searchRequests);
    }else
    if(selectedParams == 2){
      if(searchRequests && searchRequests != "" && searchRequests.length > 0) {
        var statusStr = "";
        for (let index = 0; index < requestStatuses.length; index++) {
          if(getStatus(index).toLowerCase().includes(searchRequests.toLowerCase()))
            statusStr += index + ';';
        }
        if(statusStr == "")
          setStatus("-1");
        else
          setStatus(statusStr);
      }else setStatus(null);
    }else
    if(selectedParams == 3){
      if(searchRequests && searchRequests != "" && searchRequests.length > 0) {
        var levelStr = "";
        for (let index = 0; index < requestLevels.length; index++) {
          if(getLevel(index).toLowerCase().includes(searchRequests.toLowerCase()))
            levelStr += index + ';';
        }
        if(levelStr == "")
          setLevel("-1");
        else
          setLevel(levelStr);

      }else setLevel(null);
    }else
    if(selectedParams == 4 && Number.isInteger(searchRequests)){
      setLicenseId(searchRequests);
    }else
    if(selectedParams == 5){
      setProductName(searchRequests);
    }else
    if(selectedParams == 6){
      setCompanyName(searchRequests);
    }

  };

  const handleScroll= (event) =>{
    var scrolled = document.scrollingElement.scrollTop;
    if(scrolled>100){
        setFixeTableHead(true);
      }else{
        setFixeTableHead(false);
      }
  };

  const showTableHead = () => {
    if(fixeTableHead){
      return (
        <div className="page-item-header-fixed">
          <Container>
          <div className="bg-white">
            <Form.Group className='form-group-bar'>
                <Form.Control id="filter" as="select" value={selectedParams} onChange={(e) => setParams(e.target.value)}>
                  {tbodyTh.map((s, index) => (
                    <option key={index} value={index}>
                      {s}
                    </option>
                  ))}
                </Form.Control>
                  <Form.Control
                    type="search"
                    placeholder=""
                    className="me-2 form-group-bar-search"
                    aria-label="Search"
                    onChange={(e) => setSearchRequests(e.target.value)}
                  />
                  <button variant="outline-success" 
                  className="me-2 form-group-bar-search-button border-left-0 border"
                  onClick={handleSubmitSearch}></button>
            </Form.Group>
            <CardWrapper theme="light" title="Запити">
              <div className="card card-request card-request-admin">
                <div className="card-body request-item-header row bg-light card-request-item" >
                  <div className="color-td-heder" id="heder2"></div>
                  <div className="col" onClick={() => setSort(0)}><span role="button">Дата</span></div>
                  <div className="col" onClick={() => setSort(1)}><span role="button">Статус</span></div>
                  <div className="col" onClick={() => setSort(2)}><span role="button">Рівень</span></div>
                  <div className="col" onClick={() => setSort(3)}><span role="button">Ліцензія</span></div>
                  <div className="col request-admin-list-product" onClick={() => setSort(4)}><span role="button">Продукт</span></div>
                  <div className="col request-admin-list-company" onClick={() => setSort(5)}><span role="button">Замовник</span></div>
                  <div className="col request-admin-list-show"><span role="button"></span></div>
                </div>  
              </div>
            </CardWrapper>
          </div> 
          </Container> 
        </div>  
      );
    }
    }

  useEffect(() => {
    async function fetchData() {
      console.log(selectedStatus);
      const result = await API.get('/request/all', {
        params: {
          page: page,
          sort: sort,
          pageSize: pageSize,
          status: selectedStatus,
          level: selectedLevel,
          date: selectedDate,
          licenseId: selectedLicenseId,
          productName: selectedProductName,
          companyName: selectedCompanyName,
        },
      });
      
      window.addEventListener("scroll", function (ev) {
        handleScroll(ev);
      }, false);

      if (result.data) {
        setRequests(result.data);
        setRequestsAll(result.data);
      }

      const countResult = await API.get('/request/count', {
        params: {
          status: selectedStatus,
          level: selectedLevel,
          date: selectedDate,
          licenseId: selectedLicenseId,
          productName: selectedProductName,
          companyName: selectedCompanyName,
        },
      });
      if (countResult.data) {
        setPageCount(Math.ceil(countResult.data.count / pageSize));
      }
    }

    fetchData();

  }, [page, sort, selectedStatus, selectedLevel, selectedDate, selectedLicenseId, selectedProductName, selectedCompanyName]);

  let requestsFilter = requests.filter(it => new RegExp('oli', "i").test(it.name));
  return (
    <div>
    <div>
      <Form.Group className='form-group-bar'>
          <Form.Control id="filter" as="select" value={selectedParams} onChange={(e) => setParams(e.target.value)}>
            {tbodyTh.map((s, index) => (
              <option key={index} value={index}>
                {s}
              </option>
            ))}
          </Form.Control>
            <Form.Control
              type="search"
              placeholder=""
              className="me-2 form-group-bar-search"
              aria-label="Search"
              onChange={(e) => setSearchRequests(e.target.value)}
            />
            <button variant="outline-success" 
            className="me-2 form-group-bar-search-button border-left-0 border"
            onClick={handleSubmitSearch}></button>
      </Form.Group>
    </div>
      <CardWrapper theme="light" title="Запити">
        <div className="card card-request card-request-admin">
          {showTableHead()}
          <div className="card-body request-item-header row bg-light card-request-item" >
            {/* <heder className="heder"> */}
            <div className="color-td-heder" id="heder2"></div>
            <div className="col" onClick={() => setSort(0)}><span role="button">Дата</span></div>
            <div className="col" onClick={() => setSort(1)}><span role="button">Статус</span></div>
            <div className="col" onClick={() => setSort(2)}><span role="button">Рівень</span></div>
            <div className="col" onClick={() => setSort(3)}><span role="button">Ліцензія</span></div>
            <div className="col request-admin-list-product" onClick={() => setSort(4)}><span role="button">Продукт</span></div>
            <div className="col request-admin-list-company" onClick={() => setSort(5)}><span role="button">Замовник</span></div>
            <div className="col request-admin-list-show"><span role="button"></span></div>
            {/* </heder> */}
          </div>  
          {/* {requestsFilter.map((r) => ( */}
          {requests.map((r) => (
           <div className="card-request-item"> <RequestItem request={r} admin key={r.requestId} onClick={(id) => history.push('/admin/request/' + id)} /></div>
          ))}
        </div>
        {pageCount > 1 && <Pagination page={page ?? 1} pageCount={pageCount} path="/admin/request/list/" />}
      </CardWrapper>
    </div>
  );
}


export default RequestAdminList;
