import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import CardWrapper from '../../components/general/CardWrapper';
import InputField from '../../components/general/InputField';
import API from '../../services/api';
import './Register.css';
import {
  validateName,
  validatePhone,
  validateEmail,
  validateERDPOU,
  validateRequired,
  validatePassword,
  validateNumber,
} from '../../services/validations';

function Register() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [allPageMessage, setAllPageMessage] = useState(null);
  const [showAllPagMessage, setShowAllPagMessage] = useState(null);
  const [showPassword, setShowPassword] = useState("password");
  const [showConfirmPassword, setShowConfirmPassword] = useState("password");
  const history = useHistory();

  const handleSubmit = (values) => {
    if (values.password !== values.confirmPassword) {
      setErrorMessage('Паролі мають співпадати');//Confirm password must be equal the password
      return;
    }

    let showAllPagMessage = "Зачекайте будь ласка...";
    setShowAllPagMessage(showAllPagMessage);
    setAllPageMessage(true);
    document.getElementById("FormikRegister").style.display = "none";
    document.getElementById("btnRegister").style.display = "none";
    console.log(values.name);
    console.log(values.phone);
    API.post('/account/register', {
        userName: values.name,
        phoneNumber: values.phone,
        email: values.email,
        password: values.password,
        edrpou: values.erdpou,
        licenseId: values.licenseId,
      })
      .then(() => {
        history.push('/account/confirm');
      })
      .catch((error) => {
        let message;
        document.getElementById("btnRegister").style.display = "block";
        if (error.response) {
          message = error.response.data.message;
        } else if (error.request) {
          message = error.request.data;
        }
        setErrorMessage(message);
        if(message && message.includes("відновлення паролю")){
          let showAllPagMessage = "Користувача з вказаною електронною поштою зареєстровано. Скористайтесь функцією відновлення паролю.";
          setShowAllPagMessage(showAllPagMessage);
          setAllPageMessage(true);
          document.getElementById("FormikRegister").style.display = "none";
        }else
        {
          setAllPageMessage(false);
          document.getElementById("FormikRegister").style.display = "block";
        }
      });
    };

  const checkError = (touched, errors) => {
    return (
      (touched.email && errors.email) ||
      (touched.erdpou && errors.erdpou) ||
      (touched.licenseId && errors.licenseId) ||
      (touched.password && errors.password) ||
      (touched.confirmPassword && errors.confirmPassword)
    );
  };

  const showCardWrapper = () => {
    if(allPageMessage){
      return (
        <CardWrapper>
        <h4>
          {showAllPagMessage}
        </h4>
        <div className="btn-login-conteiner" id="btnRegister">
          <a class="button" id="btn-login" onClick={()=>{setAllPageMessage(false);setErrorMessage(null); document.getElementById("FormikRegister").style.display = "block";}}>ОК</a>
        </div>
      </CardWrapper>
      );
    }
    }

  const showFormik = () => {
    return (
    <div>
      {showCardWrapper()}
      <div id="FormikRegister">
        <Card className="auth-card">
          <Card.Body>
            <Card.Title className="auth-card-title">Реєстрація</Card.Title>
            <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  email: '',
                  erdpou: '',
                  licenseId: '',
                  password: '',
                  confirmPassword: '',
                }}
                onSubmit={handleSubmit}
              >
              {({ touched, errors }) => (
                <Form>
                  {errorMessage && <div className="text-danger my-2">{errorMessage}</div>}
                  <InputField
                    name="name"
                    type="name"
                    displayName="ПІБ"
                    touched={touched.name}
                    error={errors.name}
                    placeholder="Введіть прізвище, ім'я та по батькові"
                    validation={validateName}
                  />
                  <InputField
                    name="phone"
                    type="phone"
                    displayName="Телефон"
                    touched={touched.phone}
                    error={errors.phone}
                    placeholder="+38 ___ ___ __ __"
                    validation={validatePhone}
                  />

                  <InputField
                    name="email"
                    type="email"
                    displayName="Електронна пошта"
                    touched={touched.email}
                    error={errors.email}
                    placeholder="Введіть електронну адресу"
                    validation={validateEmail}
                  />

                  <InputField
                    name="erdpou"
                    type="text"
                    displayName="ЄРДПОУ"
                    touched={touched.erdpou}
                    error={errors.erdpou}
                    placeholder="Введіть ЄРДПОУ"
                    validation={validateERDPOU}
                  />

                  <InputField
                    name="licenseId"
                    type="text"
                    displayName="ID ліцензії"
                    touched={touched.licenseId}
                    error={errors.licenseId}
                    placeholder="Введіть ідентифікатор ліцензії"
                    validation={validateNumber}
                  />

                  <Button variant="primary" id="btn-primary-user" className="btn btn-primary-look-Login btn-sm" onClick={() => { if(showPassword === "password") {setShowPassword("text");} else {setShowPassword("password");} }}></Button>

                  <InputField
                    name="password"
                    type={showPassword} 
                    displayName="Пароль"
                    touched={touched.password}
                    error={errors.password}
                    placeholder="Введіть пароль"
                    validation={validatePassword}
                  />

                  <Button variant="primary" id="btn-primary-user" className="btn btn-primary-look-Login btn-sm" onClick={() => { if(showConfirmPassword === "password") {setShowConfirmPassword("text");} else {setShowConfirmPassword("password");} }}></Button>
                  <InputField 
                    name="confirmPassword"
                    type={showConfirmPassword} 
                    // type="password"
                    displayName="Підтвердьте пароль"
                    touched={touched.confirmPassword}
                    error={errors.confirmPassword}
                    placeholder="Підтвердьте пароль"
                    validation={validateRequired}
                  />

                  <div className="my-2">
                    Вже є аккаунт? <Link to="/account/signin">Увійти</Link>
                  </div>
                  <div className="btn-login-conteiner">
                    <Button 
                    variant="primary" 
                    // className="btn-block" 
                    id="btn-register"
                    type="submit" 
                    disabled={checkError(touched, errors)}>
                      Зареєструватися
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
        </div>
      </div>
    );
  };

  return (
    <div>
        {showFormik()}
    </div>
  );
}

export default Register;
