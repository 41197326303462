import { useEffect } from 'react';
import { useState } from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useParams } from 'react-router';
import CardWrapper from '../../components/general/CardWrapper';
import LicenseList from '../../components/LicenseList/LicenseList';
import API from '../../services/api';
import { Link } from 'react-router-dom';

function CompanyView() {
  const [company, setCompany] = useState({ companyId: 'Loading...', name: 'Loading...', edrpou: 'Loading...' });
  const [licenses, setLicenses] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (id) {
        const result = await API.get('/admin/company/' + id);
        if (result.data) {
          setCompany({
            companyId: result.data.companyId,
            name: result.data.name,
            edrpou: result.data.edrpou,
          });
          if (result.data.licenses) {
            setLicenses(result.data.licenses);
          }
        }
      }
    }

    fetchData();
  }, [id]);

  return (
    <div>
      <input name="action" onClick={() =>{window.history.back(); return false;}} className="btn btn-secondary" type="submit" value="⬅"/>
    <CardWrapper theme="light" title="Замовник">
      <FormGroup>
        <FormLabel>ID замовника:</FormLabel>
        <FormControl value={company.companyId} readOnly/>
      </FormGroup>
      <FormGroup>
        <FormLabel>Замовник:</FormLabel>
        <FormControl value={company.name} readOnly />
      </FormGroup>
      <FormGroup>
        <FormLabel>ЄРДПОУ</FormLabel>
        <FormControl value={company.edrpou} readOnly />
      </FormGroup>
      <LicenseList licenses={licenses} />
    </CardWrapper>
    </div>
  );
}

export default CompanyView;
