import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router';
import CompanyCreate from '../../pages/Company/CompanyCreate';
import CompanyEdit from '../../pages/Company/CompanyEdit';
import CompanyList from '../../pages/Company/CompanyList';
import CompanyView from '../../pages/Company/CompanyView';
import LicenseCreate from '../../pages/License/LicenseCreate';
import LicenseEdit from '../../pages/License/LicenseEdit';
import LicenseListPage from '../../pages/License/LicenseListPage';
import LicenseView from '../../pages/License/LicenseView';
import NotFound from '../../pages/NotFound/NotFound';
import ProductCreate from '../../pages/Product/ProductCreate';
import ProductEdit from '../../pages/Product/ProductEdit';
import ProductList from '../../pages/Product/ProductList';
import ProductView from '../../pages/Product/ProductView';
import RequestAdmin from '../../pages/RequestAdmin/RequestAdmin';
import RequestAdminList from '../../pages/RequestAdmin/RequestAdminList';
import UserList from '../../pages/UsersList/UsersList';
import PrivateRoute from '../general/PrivateRoute';
import { useAuth } from '../general/ProvideAuth';
import AdminNavBar from '../Navbar/AdminNavBar';
import './AdminLayout.css';

function AdminLayout() {
  const auth = useAuth();

  return auth.user.role !== 'admin' ? (
    <Redirect to="/" />
  ) : (
    <>
      <AdminNavBar />
      <Container>
        <Switch>
          <PrivateRoute exact path="/admin/company">
            <CompanyCreate />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/company/list">
            <CompanyList />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/company/:id">
            <CompanyView />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/company/edit/:id">
            <CompanyEdit />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/license">
            <LicenseCreate />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/license/list">
            <LicenseListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/license/:id">
            <LicenseView />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/license/edit/:id">
            <LicenseEdit />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/product">
            <ProductCreate />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/product/list">
            <ProductList />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/product/:id">
            <ProductView />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/product/edit/:id">
            <ProductEdit />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/request/list/:page?">
            <RequestAdminList />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/request/:id">
            <RequestAdmin />
          </PrivateRoute>
          <PrivateRoute exact path="/admin/users">
            <UserList />
          </PrivateRoute>
          <PrivateRoute path="/">
            <Redirect to="/admin/request/list" />
          </PrivateRoute>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Container>
    </>
  );
}

export default AdminLayout;
