import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { Container } from 'react-bootstrap';
import CardWrapper from "../../components/general/CardWrapper";
import API from "../../services/api";
import './UsersList.css';

function UserList() {
  const [users, setUsers] = useState([]);
  const [fixeTableHead, setFixeTableHead] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const response = await API.get("user/all");
      if (response.status === 200) {
        setUsers(response.data);
      }
    }

    window.addEventListener("scroll", function (ev) {
      handleScroll(ev);
    }, false);

    fetchData();
  }, []);

  const handleScroll= (event) =>{
    var scrolled = document.scrollingElement.scrollTop;
    if(scrolled>100){
        setFixeTableHead(true);
      }else{
        setFixeTableHead(false);
      }
  };

  const showTableHead = () => {
    if(fixeTableHead){
      return (
        <div className="page-item-header-fixed">
          <Container>
            <div className="bg-white">
              <CardWrapper title="Користувачі" theme="light">
                <Table sm bordered className="request-item-header">
                  <thead>
                    <tr className="bg-light border-right-left-bottom-0" >
                      <th className="page-users-list-number">№</th>
                      <th className="page-users-list-company">Замовник</th>
                      <th className="page-users-list-email">Email</th>
                      <th className="page-users-list-license">Ліцензія</th>
                      <th className="page-users-list-phone">Телефон</th>
                    </tr>
                  </thead>
                </Table>
              </CardWrapper>
            </div> 
          </Container> 
        </div>  
      );
    }
    }

  let countEl = 1;
  return (
    <CardWrapper title="Користувачі" theme="light">
      <Table sm bordered className="request-item-header">
        {showTableHead()}
        <thead>
          <tr className="bg-light border-right-left-bottom-0" >
            <th className="page-users-list-number">№</th>
            <th className="page-users-list-company">Замовник</th>
            <th className="page-users-list-email">Email</th>
            <th className="page-users-list-license">Ліцензія</th>
            <th className="page-users-list-phone">Телефон</th>
          </tr>
        </thead>
        <tbody>
          {users && users.map(u => (
            <tr>
              <td>{countEl++}</td>
              <td>{u.companyName}</td>
              <td>{u.email}</td>
              <td>{u.licenseId}</td>
              <td>{u.phoneNumber}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </CardWrapper>
  );
}

export default UserList;