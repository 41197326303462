import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CardWrapper from '../general/CardWrapper';

function LicenseList({ licenses }) {
  function ProductView() {
    if (!licenses) {
      return 'Loading...';
    }
  }
  return (
    <CardWrapper theme="light" title="Ліцензії:">
      <ListGroup>
        {ProductView()}
        {licenses.map((l) => (
          <Link
            to={`/admin/license/${l.licenseId}`}
            className="list-group-item list-group-item-action"
            key={l.licenseID}
          >
            №{l.licensesIdStr} - {l.product.name} - {l.company.name}
          </Link>
        ))}
      </ListGroup>
    </CardWrapper>
  );
}

export default LicenseList;
