import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import UserWidget from '../general/UserWidged';
import './NavigationBar.css';

function NavigationBar() {
  return (
    <Navbar variant="light" expand="lg" className="form-main-navbar border-bottom box-shadow mb-3">
      <Container>
        <Navbar.Brand>
          <Link to="/" className="navbar-title">
            KAI Service
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-expanded" />
        <Navbar.Collapse id="navbar-expanded">
          <Nav className="mr-auto">
            <NavLink to="/request" exact className="nav-item" activeClassName="active">
              Створити новий запит
            </NavLink>
            <NavLink to="/request/list" className="nav-item" activeClassName="active">
              Запити
            </NavLink>
          </Nav>
          <UserWidget />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
