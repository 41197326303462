import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import InputField from '../../components/general/InputField';
import API from '../../services/api';
import {
  validateEmail,
  validateERDPOU,
  validateRequired,
  validatePassword,
  validateNumber,
} from '../../services/validations';

function Register() {
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();

  const handleSubmit = (values) => {
    if (values.password !== values.confirmPassword) {
      setErrorMessage('Confirm password must be equal the password');
      return;
    }
    API.post('/account/register', {
      email: values.email,
      password: values.password,
      edrpou: values.erdpou,
      licenseId: values.licenseId,
    })
      .then(() => {
        history.push('/account/confirm');
      })
      .catch((error) => {
        let message;
        if (error.response) {
          message = error.response.data.message;
        } else if (error.request) {
          message = error.request.data;
        }
        setErrorMessage(message);
      });
  };

  const checkError = (touched, errors) => {
    return (
      (touched.email && errors.email) ||
      (touched.erdpou && errors.erdpou) ||
      (touched.licenseId && errors.licenseId) ||
      (touched.password && errors.password) ||
      (touched.confirmPassword && errors.confirmPassword)
    );
  };

  return (
    <Card className="auth-card">
      <Card.Body>
        <Card.Title className="auth-card-title">Відновити пароль</Card.Title>
        <Formik
          initialValues={{
            email: '',
            erdpou: '',
            licenseId: '',
            password: '',
            confirmPassword: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ touched, errors }) => (
            <Form>
              {errorMessage && <div className="text-danger my-2">{errorMessage}</div>}
              <InputField
                name="email"
                type="email"
                displayName="Електронна пошта"
                touched={touched.email}
                error={errors.email}
                placeholder="Введіть свою електронну адресу"
                validation={validateEmail}
              />

              <div className="my-2">
              Згадали пароль? <Link to="/account/signin">Перейти до авторизації</Link>
              </div>
              <div className="btn-login-conteiner">
                <Button 
                variant="primary" 
                // className="btn-block" 
                id="btn-register"
                type="submit" 
                disabled={checkError(touched, errors)}>
                  Скинути пароль
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default Register;
