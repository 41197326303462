import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from '../general/PrivateRoute';
import NavigationBar from '../Navbar/NavigationBar';
import Request from '../../pages/Request/Request';
import RequestList from '../../pages/Request/RequestList';
import SuccessCreated from '../../pages/Request/SuccessCreated';
import { useAuth } from '../general/ProvideAuth';
import RequestViewer from '../../pages/Request/RequestViewer';
import NotFound from '../../pages/NotFound/NotFound';
import UserProfile from '../../pages/UserProfile/UserProfile';
import './MainLayout.css';

function MainLayout() {
  const auth = useAuth();
  return (
    <>
      <NavigationBar />
      <Container>
        <Switch>
          <PrivateRoute exact path="/">
            {auth.user.role === 'admin' ? <Redirect to="/admin" /> : <Redirect to="/request" />}
          </PrivateRoute>
          <PrivateRoute exact path="/request">
            <Request />
          </PrivateRoute>
          <PrivateRoute exact path="/request/list">
            <RequestList />
          </PrivateRoute>
          <PrivateRoute exact path="/request/success/:id">
            <SuccessCreated />
          </PrivateRoute>
          <PrivateRoute exact path="/request/:id">
            <RequestViewer />
          </PrivateRoute>
          <PrivateRoute exact path="/user">
            <UserProfile />
          </PrivateRoute>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Container>
    </>
  );
}

export default MainLayout;
