import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from './ProvideAuth';
import './UserWidged.css';

function UserWidget() {
  const auth = useAuth();
  return (
    <NavDropdown className="navbar-nav" title={auth.user?.email} id="basic-nav-dropdown">
      {auth.user.role === 'admin' ? (
        <Link className="dropdown-item" to="/admin">
          Адміністратор
        </Link>
      ) : (
        <Link className="dropdown-item" to="/user">
          Профіль
        </Link>
      )}
      <NavDropdown.Divider />
      <Link className="dropdown-item" to="/account/signout">
        Вийти
      </Link>
    </NavDropdown>
  );
}

export default UserWidget;
