import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import CardWrapper from '../../components/general/CardWrapper';
import { useAuth } from '../../components/general/ProvideAuth';
import API from '../../services/api';

function ConfirmEmail() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const userId = query.get('userId');
  const code = query.get('code');

  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    console.log(code);
    if (userId && code) {
      API.post('account/confirm', {
        userId: userId,
        code: code,
      })
        .then((res) => {
          auth.login(res.data.token);
          history.push('/');
        })
        .catch(() => {
          setErrorMessage('Error');
        });
    }
  }, [auth, userId, code, history]);

  if (errorMessage) {
    return <h1>{errorMessage}</h1>;
  }

  if (!userId || !code) {
    return (
      <CardWrapper>
        <h4>
          Вам на пошту прийшов лист, перейдіть за посиланням, щоб підтвердити аккаунт. Якщо лист не прийшов перевірте
          спам.
        </h4>
      </CardWrapper>
    );
  }

  return null;
}

export default ConfirmEmail;
