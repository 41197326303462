import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CardWrapper from '../../components/general/CardWrapper';
import ProductForm from '../../components/ProductForm/ProductForm';
import API from '../../services/api';

function ProductEdit() {
  const [product, setProduct] = useState(null);
  const [errorMessage, setError] = useState(null);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const result = await API.get('/admin/product/' + id);
      if (result.data) {
        setProduct(result.data);
      }
    }

    fetchData();
  }, [id]);

  function onUpdate(values) {
    API.put('/admin/product/' + id, {
      name: values.productName,
    })
      .then(() => {
        history.push('/admin/product/list');
      })
      .catch((errors) => {
        if (errors.response?.data?.product) {
          setError(errors.response.data.product.join(', '));
        }
      });
  }

  if (!product) {
    return (
      <>
        <h2>Завантаження...</h2>
        <Link to="/admin/product/list" className="btn btn-secondary">
          Назад
        </Link>
      </>
    );
  }

  return (
    <CardWrapper theme="light" title="Редагувати">
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      <ProductForm product={product} type="edit" onSubmit={onUpdate} />
    </CardWrapper>
  );
}

export default ProductEdit;
