import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import UserWidget from '../general/UserWidged';
import './AdminNavBar.css';
import './AdminNavBarForm.css';

function AdminNavBar() {
  return (
    <Navbar variant="light" bg="light" expand="lg" className="form-main-navbar border-bottom box-shadow mb-3">
      <Container>
        <Navbar.Brand>
          <Link to="/admin" className="navbar-brand">
            KAI Адміністратор
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-expanded" />
        <Navbar.Collapse id="navbar-expanded">
          <Nav className="mr-auto">
            <NavLink to="/admin/company/list" className="nav-link" activeClassName="active">
              Замовники
            </NavLink>
            <NavLink to="/admin/product/list" className="nav-link" activeClassName="active">
              Продукти
            </NavLink>
            <NavLink to="/admin/license/list" className="nav-link" activeClassName="active">
              Ліцензії
            </NavLink>
            <NavLink to="/admin/request/list" className="nav-link" activeClassName="active">
              Запити
            </NavLink>
            <NavLink to="/admin/users" className="nav-link" activeClassName="active">
              Користувачі
            </NavLink>
          </Nav>
          <UserWidget />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminNavBar;
