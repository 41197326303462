import { useEffect } from 'react';
import { useState } from 'react';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CardWrapper from '../../components/general/CardWrapper';
import LicenseList from '../../components/LicenseList/LicenseList';
import API from '../../services/api';

function ProductView() {
  const { id } = useParams();
  const [product, setProduct] = useState({ productId: 'Loading...', name: 'Loading...' });
  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (Number.parseInt(id)) {
          console.log(id);
          const result = await API.get('/admin/product/' + id);
          console.log(result);
          if (result.data) {
          setProduct({
            productId: result.data.productId,
            name: result.data.name,
          });
          if (result.data.licenses) {
            setLicenses(result.data.licenses);
          }
        }
      }
    }

    fetchData();
  }, [id]);

  return (
    <div>
        <input name="action" onClick={() =>{window.history.back(); return false;}} className="btn btn-secondary" type="submit" value="⬅"/>
    <CardWrapper theme="light" title="Продукт">
      <FormGroup>
        <FormLabel>ID продукту:</FormLabel>
        <FormControl value={product.productId} />
      </FormGroup>
      <FormGroup>
        <FormLabel>Назва продукту:</FormLabel>
        <FormControl value={product.name} />
      </FormGroup>
      <LicenseList licenses={licenses} />
    </CardWrapper>
    </div>
  );
}

export default ProductView;
