import { useEffect } from "react";
import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import CardWrapper from "../../components/general/CardWrapper";
import API from "../../services/api";
import './UserProfile.css';
import { 
  validatePassword, 
  validateName,
  validatePhone,
} from '../../services/validations';

function UserProfile() {
  const [user, setUser] = useState({ email: "", companyName: "", licenseId: "" });
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  useEffect(() => {
    async function fetchData() {
      const userData = await API.get("user/userinfo");

      if (userData.status === 200) {
        setUser(userData.data);
        if (userData.data.phoneNumber) {
          setPhone(userData.data.phoneNumber);
        }
        if (userData.data.userName) {
          setUserName(userData.data.userName);
        }
      }
    }

    fetchData();
  }, []);

  const handleChangeUserName = () => {
    setErrorMessage(null); setSuccessMessage(null);
    let errorMess = null;
    if(userName != null && userName != "")
      errorMess = validateName(userName);
    else
      errorMess = "Поле не може бути порожнім";

    if(errorMess != null){
      setErrorMessage(errorMess);
      return;
    }

    API.put("user/user_name/" + userName)
    .then((response) => {
      if (response.status === 200 || response.statusCode === 200) {
        let showAllPagMessage = "ПІБ збережено!";
        setSuccessMessage(showAllPagMessage);
      }
    })
    .catch((error, response) => {
    let message;
      if (error.response) {
        message = error.response.statusText;
      if (error.response.data != "") {
        message = error.response.data.message;
      }
      } else if (error.request && error.response.data != "") {
        message = error.request.data;
      }
      setErrorMessage(message);
    });
  };
  const handleChangePhone = () => {
    setErrorMessage(null); setSuccessMessage(null);
    let errorMess = null;
    if(phone != null && phone != "")
      errorMess = validatePhone(phone);
    else
      errorMess = "Поле не може бути порожнім";

    if(errorMess != null){
      setErrorMessage(errorMess);
      return;
    }
    
    API.put("user/phone/" + phone)
    .then((response) => {
      if (response.status === 200 || response.statusCode === 200) {
        let showAllPagMessage = "Телефон збережено!";
        setSuccessMessage(showAllPagMessage);
      }
    })
    .catch((error, response) => {
    let message;
      if (error.response) {
        message = error.response.data.message;
      } else if (error.request) {
        message = error.request.data;
      }
      setErrorMessage(message);
    });
  };

  const handleChangePassword = () => {
    setErrorMessage(null); setSuccessMessage(null);
    let errorMess = null;
    if(password != null && password != "")
      errorMess = validatePassword(password);
    else
      errorMess = "Поле не може бути порожнім";

    if(errorMess != null){
      setErrorMessage(errorMess);
      return;
    }
    
    if(user?.email != null && password != null && !validatePassword(password))
     {
      API.post('account/password_reset', {
      email: user?.email,
      password: password,
      })
      .then((response) => {
        if (response.status === 200 || response.statusCode === 200) {
          let showAllPagMessage = "Новий пароль збережено та відправлено на пошту "+ user?.email +"!";
          setSuccessMessage(showAllPagMessage);
          setPassword(null);
          if(document.getElementById("form-control-user-pass") != null && document.getElementById("form-control-user-pass").value != null)
          {document.getElementById("form-control-user-pass").value = null}
        }
      })
      .catch((error, response) => {
      let message;
        if (error.response) {
          message = error.response.statusText;
          if (error.response.data != "") {
            message = error.response.data.message;
          }
        } else if (error.request) {
          message = error.request.data;
        }
        setErrorMessage(message);
      });
    };
  };

  return (
    <CardWrapper theme="light" idCard="idCard" idTitle="idTitle" title="Профіль Користувача">
      <Form.Group as={Row} className="mb-3 row-first-child" controlId="formPlaintextEmail">
        <Form.Label column sm="2">
          Email
        </Form.Label>
        <Col sm="10">
          <Form.Control plaintext readOnly value={user?.email} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formPlaintextCompany">
        <Form.Label column sm="2">
        Замовник
        </Form.Label>
        <Col sm="10">
          <Form.Control plaintext readOnly value={user?.companyName} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formPlaintextLicense">
        <Form.Label column sm="2">
          Ліцензія
        </Form.Label>
        <Col sm="10">
          <Form.Control plaintext readOnly value={user?.licenseId} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPhone">
        <Form.Label column sm="2">
        ПІБ
        </Form.Label>
        <Col sm="3" className="form-control-name">
          {/* <Form.Control placeholder="Введіть номер телефону" id="form-control-user" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
          <Form.Control placeholder="Введіть прізвище, ім'я та по батькові" id="form-control-name" validation={validateName} value={userName} onChange={(e) => setUserName(e.target.value)} />
        </Col>
        <Col sm="2" className="form-control-name">
          <Button variant="primary" id="btn-primary-user" onClick={handleChangeUserName}>Змінити</Button>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPhone">
        <Form.Label column sm="2">
          Телефон
        </Form.Label>
        <Col sm="3" className="form-control-phone">
          {/* <Form.Control placeholder="Введіть номер телефону" id="form-control-user" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
          <Form.Control placeholder="+38 ___ ___ __ __" id="form-control-user" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </Col>
        <Col sm="2" className="form-control-phone">
          <Button variant="primary" id="btn-primary-user" onClick={handleChangePhone}>Змінити</Button>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
        <Form.Label column sm="2">
          Пароль
        </Form.Label>
        <Col sm="3" className="form-control-phone">
          <Form.Control id="form-control-user-pass" type={showPassword} value={password} onChange={(e) => { setPassword(e.target.value); 
             if(e.target.value) { setErrorMessage(validatePassword(e.target.value)); } else {setErrorMessage(null);} setSuccessMessage(null);
            }} />
        </Col>
        <Col sm="2" className="form-control-phone">
          <Button variant="primary" id="btn-primary-user" className="btn btn-primary-look-UserProfile btn-sm" onClick={() => { if(showPassword === "password") {setShowPassword("text");} else {setShowPassword("password");} }}></Button>
          <Button variant="primary" id="btn-primary-user" onClick={handleChangePassword}>Змінити</Button>
        </Col>
      </Form.Group>
      {errorMessage ? <div className="text-danger my-2 errorMessageRed" >{errorMessage}</div> : null}
      {successMessage ? <div className="text-success my-2 successMessage" >{successMessage}</div> : null}
    </CardWrapper>
  );
}

export default UserProfile;