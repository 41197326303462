import { Field, Form, Formik } from 'formik';
import { Button, FormGroup, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { validateERDPOU, validateRequired, validateRequiredLong } from '../../services/validations';

function CompanyForm({ type, onSubmit, name, edrpou }) {
  return (
    <Formik
      initialValues={{
        companyName: name,
        edrpou: edrpou,
      }}
      onSubmit={onSubmit}
    >
      {({ touched, errors }) => (
        <Form>
          <FormGroup>
            <FormLabel htmlFor="companyName">Назва замовника:</FormLabel>
            <Field
              name="companyName"
              id="companyName"
              className="form-control"
              placeholder="Введіть назву замовника"
              validate={validateRequiredLong}
            />
            {touched.companyName && errors.companyName ? (
              <span className="text-danger">{errors.companyName}</span>
            ) : null}
          </FormGroup>
          <FormGroup>
            <FormLabel htmlFor="edrpou">ЄДРПОУ:</FormLabel>
            <Field
              name="edrpou"
              id="edrpou"
              className="form-control"
              placeholder="Введіть ЄДРПОУ замовника"
              validate={validateERDPOU}
            />
            {touched.edrpou && errors.edrpou ? <span className="text-danger">{errors.edrpou}</span> : null}
          </FormGroup>
          <Button type="submit" variant={type === 'edit' ? 'warning' : 'primary'} id="btn-warning-theme" className="mr-2">
            {/* {type === 'edit' ? 'Редагувати' : 'Додати'} */}
            Зберегти
          </Button>
          <Link to="/admin/company/list" className="btn btn-secondary ml-2">
            Скасувати
          </Link>
        </Form>
      )}
    </Formik>
  );
}

export default CompanyForm;
