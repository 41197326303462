import { useEffect } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '../../components/general/ProvideAuth';

function Logout() {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
  });

  return <Redirect to="/account/signin" />;
}

export default Logout;
