import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { getLevel } from '../../services/requestService';
import { apiUrl } from '../../services/api';

function RequestSummary({ request }) {
  return (
    <>
      <FormGroup>
        <FormLabel>Email користувача:</FormLabel>
        <FormControl value={request.user?.email} readOnly />
      </FormGroup>{
        request.user?.phone?
        <FormGroup>
          <FormLabel>Телефон користувача:</FormLabel>
          <FormControl value={request.user?.phone} readOnly />
        </FormGroup>: ""
      }
      { request.user?.userName && request.user?.userName != request.user?.email ? <FormGroup>
        <FormLabel>Ім'я користувача:</FormLabel>
        <FormControl value={request.user?.userName} readOnly />
      </FormGroup>: ""}
      <FormGroup>
        <FormLabel>Замовник:</FormLabel>
        <FormControl value={request.company?.name} readOnly />
      </FormGroup>
      <FormGroup>
        <FormLabel>Продукт:</FormLabel>
        <FormControl value={request.product?.name} readOnly />
      </FormGroup>
      <FormGroup>
        <FormLabel>Ліцензія:</FormLabel>
        <FormControl value={request.license?.licenseId} readOnly />
      </FormGroup>
      <FormGroup>
        <FormLabel>Дата:</FormLabel>
        <FormControl value={new Date(request.date).toLocaleDateString()} readOnly />
      </FormGroup>
      <FormGroup>
        <FormLabel>Бажана дата:</FormLabel>
        <FormControl value={new Date(request.desiredDate).toLocaleDateString()} readOnly />
      </FormGroup>
      <FormGroup>
        <FormLabel>Рівень:</FormLabel>
        <FormControl value={getLevel(request.level)} readOnly />
      </FormGroup>
      <FormGroup>
        <FormLabel>Опис:</FormLabel>
        <FormControl value={request.description} as="textarea" rows={5} readOnly />
      </FormGroup>
      {request.files && request.files.length > 0 && request.files.filter(d => d.isAnswer === false).length > 0 && (
        <FormGroup>
          <FormLabel>Файли:</FormLabel>
          <div>
            {request.files.map((f, index) => (
              f.isAnswer == false?
              <a className="btn btn-outline-secondary ml-1" key={index} href={apiUrl + f.path}>
                {f.path.split('.')[1]}
              </a>:""
            ))}
          </div>
        </FormGroup>
      )}
    </>
  );
}

export default RequestSummary;
