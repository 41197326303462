import { useEffect } from 'react';
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import CardWrapper from '../../components/general/CardWrapper';
import API from '../../services/api';
import { Form } from 'react-bootstrap';
import './License.css';

function LicenseListPage() {
  const [licenses, setLicenses] = useState([]);
  const [licensesAll, setLicensesAll] = useState([]);
  const [searchLicenses, setSearchLicenses] = useState(null);
  const [fixeTableHead, setFixeTableHead] = useState(null);

  const tbodyTh = ['', 'Ліцензія', 'Активна', 'Продукт', 'Замовник'];
  const [selectedStatus, setStatus] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const result = await API.get('/admin/license');
      if (result.data) {
        setLicenses(result.data);
        setLicensesAll(result.data);
      }
    }

    window.addEventListener("scroll", function (ev) {
      handleScroll(ev);
    }, false);
    
    fetchData();
  }, []);

  const handleSubmitSearch = () => {
      if(selectedStatus == 1){
      console.log(searchLicenses);
      setLicenses(licensesAll.filter(element => element.licensesIdStr.includes(searchLicenses)));
    }else
    if(selectedStatus == 2){
      let statusSearch = true
      if(searchLicenses && searchLicenses.toLowerCase() == "Так".toLowerCase())
        statusSearch = true
      else 
        statusSearch = false
      setLicenses(licensesAll.filter(element => element.isActive == statusSearch));
    }else
    if(selectedStatus == 3){
      setLicenses(licensesAll.filter(element => element.product? element.product.name.toLowerCase().includes(searchLicenses.toLowerCase()):false));
    }else
    if(selectedStatus == 4){
      setLicenses(licensesAll.filter(element => element.company? element.company.name.toLowerCase().includes(searchLicenses.toLowerCase()):false));
    }
    else
    {
      let statusSearch = true
      if(searchLicenses && searchLicenses.toLowerCase() == "Так".toLowerCase())
        statusSearch = true
      else 
        statusSearch = false
      setLicenses(licensesAll.filter(element => element.licensesIdStr.includes(searchLicenses) ||
      element.isActive == (statusSearch)||
      element.companyId == (searchLicenses)||
      element.productId == (searchLicenses)
     ));
    }
  };

  const handleScroll= (event) =>{
    var scrolled = document.scrollingElement.scrollTop;
    if(scrolled>100){
        setFixeTableHead(true);
      }else{
        setFixeTableHead(false);
      }
  };

  const showTableHead = () => {
    if(fixeTableHead){
      return (
        <div className="page-item-header-fixed">
          <Container>
          <div className="bg-white">
            <Form.Group className='form-group-bar'>
                <Link to="/admin/license" className="btn-light-theme m-2">
                  Додати
                </Link>
                <Form.Control id="filter" as="select" value={selectedStatus} onChange={(e) => setStatus(e.target.value)}>
                  {tbodyTh.map((s, index) => (
                    <option key={index} value={index}>
                      {s}
                    </option>
                  ))}
                </Form.Control>
                  <Form.Control
                    type="search"
                    placeholder=""
                    className="me-2 form-group-bar-search"
                    aria-label="Search"
                    onChange={(e) => setSearchLicenses(e.target.value)}
                  />
                  <button variant="outline-success" 
                  className="me-2 form-group-bar-search-button border-left-0 border"
                  onClick={handleSubmitSearch}></button>
            </Form.Group>
            <CardWrapper theme="light" title="Ліцензії">
              <Table bordered className="request-item-header">
                <thead className="bg-light border-right-left-bottom-0">
                  <tr>
                    <th className="page-users-list-license">Ліцензія</th>
                    <th className="page-users-list-license">Активна</th>
                    <th>Продукт</th>
                    <th>Замовник</th>
                    <th className="page-license-list-view"></th>
                  </tr>
                </thead>
              </Table>
            </CardWrapper>
          </div> 
          </Container> 
        </div>  
      );
    }
    }

  return (
    <div>
      {showTableHead()}
    <div>
      <Form.Group className='form-group-bar'>
          <Link to="/admin/license" className="btn-light-theme m-2">
            Додати
          </Link>
          <Form.Control id="filter" as="select" value={selectedStatus} onChange={(e) => setStatus(e.target.value)}>
            {tbodyTh.map((s, index) => (
              <option key={index} value={index}>
                {s}
              </option>
            ))}
          </Form.Control>
            <Form.Control
              type="search"
              placeholder=""
              className="me-2 form-group-bar-search"
              aria-label="Search"
              onChange={(e) => setSearchLicenses(e.target.value)}
            />
            <button variant="outline-success" 
            className="me-2 form-group-bar-search-button border-left-0 border"
            onClick={handleSubmitSearch}></button>
      </Form.Group>
    </div>
      <CardWrapper title="Ліцензії" theme="light">
        <Table bordered className="request-item-header">
          <thead className="bg-light border-right-left-bottom-0">
            <tr>
              <th className="page-users-list-license">Ліцензія</th>
              <th className="page-users-list-license">Активна</th>
              <th>Продукт</th>
              <th>Замовник</th>
              <th className="page-license-list-view"></th>
            </tr>
          </thead>
          <tbody>
            {licenses.map((l) => (
              <tr key={l.licenseId} className='text-center'>
                <td>{l.licenseId}</td>
                <td>{l.isActive && "Так"} {l.isActive || "Ні"} </td>
                {/* <td>{l.product? l.product.name:l.productId}</td> */}
                <td>{l.product? l.product.name:l.productId}</td>
                <td>{l.company? l.company.name:l.productId}</td>
                <td className='text-center btn-record-view-edit'>
                  <Link to={'/admin/license/edit/' + l.licenseId} className="btn btn-sm btn-warning-edit ml-2">
                    {/* Редагувати */}
                  </Link>
                  <Link to={'/admin/license/' + l.licenseId} className="btn btn-sm btn-primary-look">
                    {/* Переглянути */}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardWrapper>
    </div>
  );
}

export default LicenseListPage;
