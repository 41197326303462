import { Card } from 'react-bootstrap';
import { getLevel, getLevelInvariant, getStatus } from '../../services/requestService';
import './RequestItem.css';
import '../../services/DateTimeFormat';

function RequestItem({ request, onClick, admin }) {
  const styles = `request-item request-item-all-${getLevelInvariant(request.level)?.toLowerCase()}`;
  const stylesw = `color-td request-item-${getLevelInvariant(request.level)?.toLowerCase()}`;
  let processedDate = new Date(request.processedDate);
  if (processedDate.getUTCFullYear() === 0) {
    processedDate = 'Дата не встановлена';
  }
  console.log("admin " +admin);

  return (
    <Card className={styles} tabindex="1">
    {/* <Card className={styles} onClick={() => onClick(request.requestId)}> */}
      <Card.Body>
        <div className="row " id="colCol">
          <div className={stylesw}></div>
          <div className="col">{request.requestId} - {new Date(request.date).format("dd.mm.yyyy")}</div>
          <div className="col">{getStatus(request.status)}</div>
          <div className="col">{getLevel(request.level)}</div>
          <div className="col">{request.license?.licenseId}</div>
          <div className="col text-left request-admin-list-product">{request.product?.name}</div>
          {admin && <div className="col text-left request-admin-list-company">Замовник - {request.company?.name}<br/> 
            { (request.user?.userName && request.user?.userName != request.user?.email ? request.user?.userName +", ":"") + 
            request.user?.email + 
            (request.user?.phone ? ", " +request.user?.phone:"") }</div>}
          <div className="col text-left request-admin-list-show  btn-primary-look" onClick={() => onClick(request.requestId)}>
            {/* Переглянути */}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default RequestItem;
