import { useState } from 'react';
import API from './api';
import JwtService from './jwtService';

let requestInterceptor;
let responseInterceptor;

const settingAuthorize = (token, logout) => {
  requestInterceptor = API.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  responseInterceptor = API.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

const removeAuthorize = () => {
  API.interceptors.request.eject(requestInterceptor);
  API.interceptors.response.eject(responseInterceptor);
};

export function useAuthProvider() {
  const [user, setUser] = useState(null);

  const logout = () => {
    JwtService.removeToken();
    setUser(null);
    removeAuthorize();
  };

  const login = (token) => {
    JwtService.setToken(token);
    const newUser = JwtService.getUser(token);
    setUser(newUser);
    settingAuthorize(token, logout);
  };

  const token = JwtService.getToken();
  if (!user && token) {
    const localUser = JwtService.getUser(token);
    setUser(localUser);
    settingAuthorize(token, logout);
  }

  return {
    user,
    login,
    logout,
  };
}
